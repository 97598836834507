export const PASSWORD_MIN_LENGTH = 8;
export const NAME_MIN_LENGTH = 1;

export const email = [{
  trigger: 'blur',
  required: true,
  message: 'E-Mail ist erforderlich.'
}, {
  trigger: 'change',
  type: 'email',
  message: 'E-Mail ist nicht gültig.'
}];

export const name = [{
  required: true,
  message: 'Bitte gib einen Namen ein.'
}, {
  min: 3,
  message: `Mindestend 3 Zeichen.`,
  trigger: 'change'
}];

export const password = [{
  required: true,
  message: 'Passwort ist erforderlich.',
  trigger: 'change'
}, {
  min: PASSWORD_MIN_LENGTH,
  message: `Mindestend ${PASSWORD_MIN_LENGTH} Zeichen.`,
  trigger: 'change'
}];

export const workspaceName = [{
  required: true,
  message: 'Bitte gib einen Namen ein.',
  trigger: 'change'
}, {
  min: 3,
  message: `Mindestend 3 Zeichen.`,
  trigger: 'change'
}];
